import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Lind: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Lind,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Lind"
          d="M964.4,1013.7L964.4,1013.7c-3.3,2.8-12.1,10-15.6,12.8c-0.9,3.6-0.7,8-1.9,11.6c-1.7,1.9-5.1,3-6.9,5 c-2.5,0.6-9.8,2.5-12.1,3.4c-1.1-0.2-4.2,2.5-5,3.1c0,0-6.2,4.1-6.2,4.1c-0.5,1.3-2.5,2.2-3.6,2.9H913l-39-44.6l26-10l48-8 L964.4,1013.7z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 909.4163 1026.5735)"
        >
          Lind
        </text>
      </g>
    </>
  );
});
